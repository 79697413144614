import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "「メタプログラミング Ruby」ことはじめ〜開発本部・ TechLunch〜",
  "date": "2017-03-14T08:30:06.000Z",
  "slug": "entry/2017/03/14/173006",
  "tags": ["medley"],
  "hero": "./2017_03_14.png",
  "heroAlt": "meta programming"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」の開発を担当している後藤です。`}</p>
    <p>{`メドレー開発本部にて隔週で行われている勉強会（TechLunch）で`}<a parentName="p" {...{
        "href": "https://www.oreilly.co.jp/books/9784873117430/"
      }}>{`メタプログラミング Ruby`}</a>{`をベースに`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%E1%A5%BF%A5%D7%A5%ED%A5%B0%A5%E9%A5%DF%A5%F3%A5%B0"
      }}>{`メタプログラミング`}</a><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`入門について発表したのでその一部を紹介したいと思います。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170313/20170313150345.png",
        "alt": null
      }}></img></p>
    <h1>{`メドレーと`}<a parentName="h1" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a></h1>
    <p>{`弊社では「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」、「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」、「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」と複数のプロダクトで`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby%20on%20Rails"
      }}>{`Ruby on Rails`}</a>{` を利用しています。`}</p>
    <p>{`スタートアップでそこまでエンジニアの数が多くないなか、エンジニアが最大限にプロダクトにコミットするために、もともとのバックグラウンドがフロントエンドエンジニアだったり、ネイティブアプリエンジニアだったりする方も`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby%20on%20Rails"
      }}>{`Ruby on Rails`}</a>{`のコードを書いています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby%20on%20Rails"
      }}>{`Ruby on Rails`}</a>{`はとても便利な`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D5%A5%EC%A1%BC%A5%E0%A5%EF%A1%BC%A5%AF"
      }}>{`フレームワーク`}</a>{`で、周辺の便利なライブラリや解説記事が整っていることもあり、必要な機能は検索すればあまり中身を理解せずとも実装できてしまったりもします。ただ、何か問題があった時のために利用している`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D5%A5%EC%A1%BC%A5%E0%A5%EF%A1%BC%A5%AF"
      }}>{`フレームワーク`}</a>{`の処理を把握しておくことはとても重要です。そして、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D5%A5%EC%A1%BC%A5%E0%A5%EF%A1%BC%A5%AF"
      }}>{`フレームワーク`}</a>{`の`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%BD%A1%BC%A5%B9%A5%B3%A1%BC%A5%C9"
      }}>{`ソースコード`}</a>{`を読み解くにはやはり`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`の言語仕様の知識が重要になってきます。`}</p>
    <p>{`また、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`の言語仕様、そして使っている`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D5%A5%EC%A1%BC%A5%E0%A5%EF%A1%BC%A5%AF"
      }}>{`フレームワーク`}</a>{`の深い理解があることでよりメンテナンスしやすい設計・実装がしやすくなるのも事実だと思います。`}</p>
    <p>{`こういった背景のもと、社内のエンジニアの`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`に対する知見を深めることはとても価値があると感じ、「`}<a parentName="p" {...{
        "href": "https://www.oreilly.co.jp/books/9784873117430/"
      }}>{`メタプログラミング Ruby`}</a>{`」をベースに`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%E1%A5%BF%A5%D7%A5%ED%A5%B0%A5%E9%A5%DF%A5%F3%A5%B0"
      }}>{`メタプログラミング`}</a><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`入門の勉強会を開催しました。`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%E1%A5%BF%A5%D7%A5%ED%A5%B0%A5%E9%A5%DF%A5%F3%A5%B0"
      }}>{`メタプログラミング`}</a><a parentName="h1" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`入門`}</h1>
    <p>{`今回は`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%E1%A5%BF%A5%D7%A5%ED%A5%B0%A5%E9%A5%DF%A5%F3%A5%B0"
      }}>{`メタプログラミング`}</a>{`入門ということで`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`で`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%E1%A5%BF%A5%D7%A5%ED%A5%B0%A5%E9%A5%DF%A5%F3%A5%B0"
      }}>{`メタプログラミング`}</a>{`をする上で必要な`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`の言語仕様の話と一つの例として method_missing の話を取り上げました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170313/20170313152735.png",
        "alt": null
      }}></img></p>
    <h2><a parentName="h2" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`のオブジェクトモデル`}</h2>
    <p>{`この`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`のオブジェクトモデルという言葉は「`}<a parentName="p" {...{
        "href": "https://www.oreilly.co.jp/books/9784873117430/"
      }}>{`メタプログラミング Ruby`}</a>{`」から拝借しています。本の中では「このメソッドはどのクラスに所属するものなのか？」「このモジュールをインクルードしたら何が起きるのか」といった質問の答えが見つかる場所と記載しています。このオブジェクトモデルには以下のようなルールが存在します。`}</p>
    <ol>
      <li parentName="ol">{`オブジェクトは 1 種類しかない。それが通常のオブジェクトかモジュールになる。`}</li>
      <li parentName="ol">{`モジュールは 1 種類しかない。それが通常のモジュール、クラス、特異クラスのいずれかになる。`}</li>
      <li parentName="ol">{`メソッドは 1 種類しかない。メソッドはモジュール（大半はクラス）に住んでいる。`}</li>
      <li parentName="ol">{`すべてのオブジェクトは（クラスも含めて）「本物のクラス」を持っている。それが通常のクラスか特異クラスである。`}</li>
      <li parentName="ol">{`すべてのクラスは（BasicObject を除いて）ひとつの祖先（`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%B9%A1%BC%A5%D1%A1%BC%A5%AF%A5%E9%A5%B9"
        }}>{`スーパークラス`}</a>{`かモジュール）を持っている。つまり、あらゆるクラスが BasicObject に向かって 1 本の継承チェーンを持っている。`}</li>
      <li parentName="ol">{`オブジェクトの特異クラスの`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%B9%A1%BC%A5%D1%A1%BC%A5%AF%A5%E9%A5%B9"
        }}>{`スーパークラス`}</a>{`は、オブジェクトのクラスである。クラスの特異クラスの`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%B9%A1%BC%A5%D1%A1%BC%A5%AF%A5%E9%A5%B9"
        }}>{`スーパークラス`}</a>{`はクラスの`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%B9%A1%BC%A5%D1%A1%BC%A5%AF%A5%E9%A5%B9"
        }}>{`スーパークラス`}</a>{`の特異クラスである。`}</li>
      <li parentName="ol">{`メソッドを呼び出すときは、`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/Ruby"
        }}>{`Ruby`}</a>{`はレシーバの本物のクラスに向かって「右へ」進み、継承チェーンを「上へ」進む。`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/Ruby"
        }}>{`Ruby`}</a>{`のメソッド探索について知るべきことは以上だ。`}</li>
    </ol>
    <p>{`(`}<a parentName="p" {...{
        "href": "https://www.oreilly.co.jp/books/9784873117430/"
      }}>{`メタプログラミング Ruby 第 2 版`}</a>{` より)`}</p>
    <h2>{`BasicObject#method_missing`}</h2>
    <p><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`でメソッド呼び出しを行った際のフローは以下になります。`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/Ruby"
        }}>{`Ruby`}</a>{`はレシーバーの本物のクラスに向かってメソッド探索を始める`}</li>
      <li parentName="ol">{`継承チェーンを BasicObject まで辿っても呼び出したメソッドが見つからなかった場合、`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/Ruby"
        }}>{`Ruby`}</a>{`はレシーバーの method_missing を呼び出す`}</li>
      <li parentName="ol">{`メソッド探索で method_missing が見つからなかった場合、BasicObject#method_missing が呼び出され、例外が投げられる`}</li>
    </ol>
    <p>{`この仕組みをうまく使って、BasicObject#method_missing が呼び出される前に自前の method_missing メソッドを呼び出すようにして動的にメソッドを定義したり動的な振る舞いをオブジェクトに加えたりすることができるのです。`}</p>
    <h1>{`勉強会の様子`}</h1>
    <p>{`ここまでお話しした内容を含め、勉強会では以下の内容を発表しました。`}</p>
    <p>{`発表資料はこちら`}</p>
    <p>{`発表時の個人的な裏テーマとして「あまり`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`が得意でない方に`}<a parentName="p" {...{
        "href": "https://www.oreilly.co.jp/books/9784873117430/"
      }}>{`メタプログラミング Ruby`}</a>{`を読みたくなるようにさせる」というものを設定していましたが、実際の発表後にちらほら`}<a parentName="p" {...{
        "href": "https://www.oreilly.co.jp/books/9784873117430/"
      }}>{`メタプログラミング Ruby`}</a>{`の話が出ていたので発表した甲斐がありました。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`今回はメドレー開発本部の技術勉強会（TechLunch）で発表した内容を紹介しました。`}</p>
    <p>{`勉強会では、まず`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`の言語要素とメソッド実行の理解を深め、その上で BasicObject#method_missing を理解し、それを使った gem のコードを読み進めました。`}</p>
    <p>{`実際に`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`での`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%E1%A5%BF%A5%D7%A5%ED%A5%B0%A5%E9%A5%DF%A5%F3%A5%B0"
      }}>{`メタプログラミング`}</a>{`に触れることで`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`への知見が深めていけたかと思います。 メドレー開発本部で実施している「TechLunch」での発表内容は今後も定期的に紹介していくので、是非メドレーブログをチェックしてみてください。`}</p>
    <h3>{`お知らせ`}</h3>
    <p>{`メドレーでは医療業界に存在する課題に IT を駆使して取り組んでいきたいデザイナー・エンジニアを募集中です。`}</p>
    <p>{`皆さまからのご応募お待ちしております。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集職種 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/jobs/">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="デザイナー＆エンジニア採用 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      